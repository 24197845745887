import React, { InputHTMLAttributes, ReactElement, useState } from 'react'
import { useRef } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import { Format } from '../../../../utils/format'
import * as S from './Input.styled'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  currency?: boolean
  error?: boolean
  prefixIcon?: ReactElement | string
  suffixIcon?: ReactElement | string
  register?: UseFormRegisterReturn
  withCounter?: boolean
}

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { prefixIcon, suffixIcon, register, withCounter, currency } = props
  const inputRef = useRef<HTMLInputElement>(null)
  const [inputValue, setInputValue] = useState(
    currency ? Format.currency(props.defaultValue?.toString() || '') : undefined
  ) // currency format
  const [textLength, setTextLength] = useState<number>(0)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (currency) {
      const value = Format.stringToNumber(e.target.value, true)
      props.onChange &&
        props.onChange({
          ...e,
          target: { ...e.target, value: value?.toString() || '' },
        })
      setInputValue(Format.currency(value?.toString()))
    } else {
      props.onChange && props.onChange(e)
    }
    withCounter && setTextLength(e.target.value.length)
  }

  const inputProps = {
    ...props,
    className: props.name,
    onChange: onChange,
  }

  return (
    <S.InputWrap
      hasPrefix={prefixIcon ? true : undefined}
      hasSuffix={suffixIcon ? true : undefined}
      withCounter={withCounter}
    >
      {prefixIcon && <S.Prefix>{prefixIcon}</S.Prefix>}
      {currency ? (
        <S.Input ref={inputRef || ref} {...inputProps} value={inputValue} />
      ) : register ? (
        <S.Input ref={inputRef || ref} {...inputProps} {...register} />
      ) : (
        <S.Input ref={inputRef || ref} {...inputProps} />
      )}
      {suffixIcon && <S.Suffix>{suffixIcon}</S.Suffix>}
      {withCounter && (
        <S.Count>
          ({textLength}/{props.maxLength})
        </S.Count>
      )}
    </S.InputWrap>
  )
})

Input.defaultProps = {}

export default Input
