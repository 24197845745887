export const Format = {
  number: (val: number) => {
    if (!val) return 0
    return new Intl.NumberFormat('en-AU').format(val)
  },
  currency: (val: string | number) => {
    if (!val) return ''
    let formatValue = new Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: 'AUD',
      minimumFractionDigits: 0,
    }).format(Number(val))
    if (val.toString().indexOf('.') > -1 && formatValue.indexOf('.') === -1) {
      formatValue = formatValue + '.'
    }
    return formatValue
  },
  stringToNumber: (string = '', float = false) => {
    if (!string || string === '$') return null
    if (string.indexOf('.') > -1) {
      const numbers = string.split('.')
      if (numbers.length > 2 || numbers[1].length > 2) {
        string = string.slice(0, -1)
      }
    }
    let number = string.toString().replace(/[^\d.]/g, '')
    if (float) return number
    return !isNaN(parseInt(number)) ? parseInt(number) : ''
  },
  moneyWithCommas: (val: any) => {
    if (val) {
      let nVal
      if (typeof val === 'number') {
        nVal = val.toString()
      } else {
        nVal = val.toString().replaceAll(',', '')
      }

      return nVal.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    return val
  },
}
