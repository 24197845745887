import styled, { css } from 'styled-components'
import tw from 'twin.macro'

export const Input = styled.input<{
  filled?: boolean
  error?: boolean
  success?: boolean
}>`
  ${tw`text-base bg-white w-full border-input rounded-base p-4 border focus:outline-none`}

  &:focus {
    ${tw`border-primary shadow-primary-short`}
  }

  ${({ filled }) => filled && css``}

  ${({ error }) =>
    error &&
    css`
      ${tw`border-danger! text-danger! shadow-danger-short!`}

      &:focus {
        ${tw`border-danger text-danger shadow-danger-short`}
      }
    `}

  ${({ success }) =>
    success &&
    css`
      // ${tw`border-success shadow-success-short`}

      // &:focus {
      //   ${tw`border-success shadow-success-short`}
      // }
    `}
`

export const Prefix = styled.span`
  transform: translateY(-50%);
  ${tw`absolute left-4 top-1/2 text-greyDarker`}
`

export const Suffix = styled.span`
  transform: translateY(-50%);
  ${tw`absolute right-4 top-1/2 text-lavenderGrey`}
`

export const InputWrap = styled.div<{
  hasPrefix?: boolean
  hasSuffix?: boolean
  withCounter?: boolean
}>`
  ${tw`relative w-full`}
  ${({ hasPrefix }) =>
    hasPrefix &&
    css`
      ${Input} {
        ${tw`pl-12`}
      }
    `}
  ${({ hasSuffix }) =>
    hasSuffix &&
    css`
      ${Input} {
        ${tw`pr-12`}
      }
    `}

  ${({ withCounter }) =>
    withCounter &&
    css`
      ${Input} {
        ${tw`pr-28`}
      }
    `}
`

export const Count = styled.div`
  transform: translateY(-50%);
  ${tw`absolute right-5 top-1/2`}
`
