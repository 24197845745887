import styled, { css } from 'styled-components'
import { DEVICES } from '../src/constants/global'

const getColWidth = (number: number) => {
  return (number / 12) * 100
}

export const Box = styled.div``

export const Col = styled.div<{
  xs?: number
  sm?: number
  md?: number
  lg?: number
  xl?: number
  flex?: boolean
}>`
  width: 100%;

  ${({ flex }) =>
    flex &&
    css`
      display: flex;
    `}

  ${({ xs }) =>
    xs &&
    css`
      @media ${DEVICES.xs} {
        width: ${getColWidth(xs)}%;
        flex: 0 0 ${getColWidth(xs)}%;
      }
    `}

  ${({ sm }) =>
    sm &&
    css`
      @media ${DEVICES.sm} {
        width: ${getColWidth(sm)}%;
        flex: 0 0 ${getColWidth(sm)}%;
      }
    `}
    
  ${({ md }) =>
    md &&
    css`
      @media ${DEVICES.md} {
        width: ${getColWidth(md)}%;
        flex: 0 0 ${getColWidth(md)}%;
      }
    `}

  ${({ lg }) =>
    lg &&
    css`
      @media ${DEVICES.lg} {
        width: ${getColWidth(lg)}%;
        flex: 0 0 ${getColWidth(lg)}%;
      }
    `}

  ${({ xl }) =>
    xl &&
    css`
      @media ${DEVICES.xl} {
        width: ${getColWidth(xl)}%;
        flex: 0 0 ${getColWidth(xl)}%;
      }
    `}
`

export const Row = styled.div<{
  itemsCenter?: boolean
  gutter?: number
  rowReverse?: boolean
  lg?: number
  xl?: number
}>`
  display: flex;
  flex-wrap: wrap;

  ${({ itemsCenter }) =>
    itemsCenter &&
    css`
      align-items: center;

      > ${Col} {
        height: 100%;
      }
    `}

  ${({ gutter }) =>
    gutter &&
    css`
      margin: 0 -${gutter}px;

      > ${Col} {
        padding: 0 ${gutter}px;
      }

      @media (max-width: 767px) {
        margin: 0 -15px;

        > ${Col} {
          padding: 0 15px;
        }
      }
    `}

  ${({ rowReverse }) =>
    rowReverse &&
    css`
      flex-direction: row-reverse;
    `}
`

export const Container = styled.div<{ fluid?: boolean }>`
  // padding: 0 1rem;
  margin: 0 auto;
  width: 100%;

  @media ${DEVICES.sm} {
    max-width: 100%;
  }

  // @media ${DEVICES.md} {
  //   padding: 0 1.5rem;
  //   max-width: 720px;
  // }

  // @media ${DEVICES.lg} {
  //   max-width: 960px;
  // }

  // @media ${DEVICES.xl} {
  //   max-width: 1140px;
  // }

  ${(props) =>
    props.fluid &&
    css`
      @media ${DEVICES.mobile} {
        padding: 0 1rem;
        max-width: 100%;
      }

      @media ${DEVICES.md} {
        padding: 0 3.125rem;
        max-width: 100%;
      }

      @media ${DEVICES.lg} {
        max-width: 100%;
      }

      @media ${DEVICES.xl} {
        max-width: 1410px;
      }
    `}
`
