export const ERROR_MESSAGE = {
  required: 'Required field',
  phoneOrMobileIsRequired: 'Phone Number or Mobile Number is required',
  email: 'Please enter a valid email',
  confirmPassword: 'Passwords must match',
  min: '{field} must be min {number} characters',
  max: '{field} must be max {number} characters',
  url: 'Please enter a valid URL',
  http: 'URL must start with http(s)://',
  number: 'Please enter a valid number',
  bedroom: 'Bedroom is required',
  bathroom: 'Bathroom is required',
  feature: '{field} can be a maximum of {number} characters',
}
