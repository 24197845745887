import { HTMLAttributes } from 'react'
import * as S from './ErrorMsg.styled'

interface ErrorMsgProps extends HTMLAttributes<HTMLSpanElement> {
  name?: string
  message?: string
  style?: React.CSSProperties
}

const ErrorMsg = (props: ErrorMsgProps) => {
  const { message } = props
  return message ? <S.ErrorMsg {...props}>{message}</S.ErrorMsg> : null
}

export default ErrorMsg
